import React from 'react';
import Image from 'next/image';
import { Layout } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
// import LanguageDropdown from '../LanguageDropdown';
import Style from './style';
import pageLogo from '../../public/logo-reseau-acteurs-habitat.png';

const { Header: AntHeader } = Layout;

const Header = function ({ pageTitle }) {
  const screens = useBreakpoint();

  return (
    <AntHeader
      style={{
        padding: 0,
        backgroundColor: '#fff'
      }}
      id="header"
    >
      <div className="headerContainer" data-cy="header">
        <div className="logoContainer">
          <div className="logoSubContainer">
            <div>
              <img
                src="/logo-reseau-acteurs-habitat.png"
                alt={`Logo ${pageTitle}`}
                data-cy="logo"
                style={{
                  maxWidth: '300px'
                }}
              />
            </div>
            {/* <Image
              src={PageLogo}
              alt="picture of a sunrise"
              // layout="fill"
              // objectFit="fit"
              data-cy="logo"
              height={'100%'}
              width={'100%'}
            /> */}
          </div>
        </div>

        {/* {!screens.xs && ( */}
        <div className="subHeader">
          <h1 className="subTitle" data-cy="page-title">
            Webconférence d'actualité | 07 décembre 2023 (14h30 à 16h00)
          </h1>
          <h2 className="title" data-cy="page-title">
            Réhabiliter le parc Hlm : le dispositif « seconde vie des logements
            locatifs sociaux» à la loupe
          </h2>
        </div>
        {/* )} */}

        {/* <LanguageDropdown /> */}
      </div>

      {pageTitle && screens.xs && <h1 className="title">{pageTitle}</h1>}

      <Style screens={screens} />
    </AntHeader>
  );
};

export default Header;
