import { useMutation } from '@apollo/client';
import { CHECK_SESSION } from '../mutations/confidentiality';
import useHeaders from '../hooks/useHeaders';

export const useCheckSession = () => {
  const headers = useHeaders();
  const [checkSession] = useMutation(CHECK_SESSION, {
    context: {
      headers
    },
  });

  return async (privacyId) => {
    try {
      const { data } = await checkSession({
        variables: {
          privacyId
        }
      });
      return {
        data: data.checkSession
      };
    } catch (error) {
      return {
        error
      };
    }
  };
};
