import {
  BookOutlined,
  CommentOutlined,
  FileOutlined,
  TranslationOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import { FaPoll } from 'react-icons/fa';
import { Badge, Tabs } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { memo, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { notificationCountState } from '../../recoil/notificationCount';
import { notificationShouldUpdateState } from '../../recoil/notificationShouldUpdate';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';
import { activeQuestionState } from '../../recoil/poll';
import {
  getContent,
  getContentPlaying,
  handleSideBar
} from '../../utils/functions/contentParams';
import useTabHeight from '../../utils/hooks/useTabHeight';
import { discussionState } from '../../recoil/discussion';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';

const Discussion = dynamic(() => import('../Discussion'));
const Chapters = dynamic(() => import('../Chapters'));
const Transcript = dynamic(() => import('../Transcript'));
const Files = dynamic(() => import('../Files'));
const Playlist = dynamic(() => import('../Playlist'));
const Poll = dynamic(() => import('../Poll'));

const { TabPane } = Tabs;

const TabsPlayer = function ({
  isEmbed,
  media,
  live,
  webTvId,
  pollId,
  playlist,
  title,
  isMuted
}) {
  const [items, setItems] = useState([]);
  const { t } = useTranslation('common');
  const screens = useBreakpoint();
  const { content, contentType, isPlaylist } = getContent(
    media,
    live,
    playlist
  );
  const { contentPlaying, contentTypePlaying, contentIdPlaying } =
    getContentPlaying(media, live, playlist);

  const { tabHeight } = useTabHeight();

  const { accentColor, pageTheme } = GetBrandSettingFromRecoil();
  const currentPlaylistContentId = GetVideoIdFromRecoil();

  const [shouldUpdate, setShouldUpdate] = useRecoilState(
    notificationShouldUpdateState
  );

  const count = useRecoilValue(notificationCountState);
  const activeQuestion = useRecoilValue(activeQuestionState);
  const [isQAndA, setIsQAndA] = useRecoilState(discussionState);

  const [activeKey, setActiveKey] = useState();

  const onUpdateTabs = (newKey) => {
    newKey !== 'discussion' && setShouldUpdate(true);
    setActiveKey(newKey);
    setTimeout(() => {
      window.dispatchEvent(new Event('resizeUseTabHeight'));
    }, 500);
  };

  // fix the is embed missing (in discussion scope, not live/media)
  // const shouldShowDiscussion = (media || live)?.shouldShowDiscussion && (media || live)?.isEmbed
  const {
    files,
    shouldShowTranscript,
    transcripts,
    discussion,
    shouldShowChapter,
    shouldShowPoll,
    shouldShowFile,
    shouldShowDiscussion
  } = handleSideBar(content, contentType, currentPlaylistContentId);

  const getDefaultActiveKey = () => {
    if (contentType === 'playlist') {
      return 'playlist';
    } else if (shouldShowDiscussion && discussion?.id) {
      return 'discussion';
    } else if (shouldShowChapter) {
      return 'chapters';
    } else if (shouldShowTranscript && transcripts?.length) {
      return 'transcript';
    } else if (shouldShowFile && files?.length) {
      return 'files';
    } else {
      return '';
    }
  };

  useEffect(() => {
    setActiveKey(getDefaultActiveKey());
  }, []);

  useEffect(() => {
    if (screens.sm) return;
    if (activeQuestion) {
      setActiveKey('poll');
    } else {
      setActiveKey(getDefaultActiveKey());
    }
  }, [activeQuestion, screens]);

  useEffect(() => {
    if (!discussion) return;
    setIsQAndA(discussion?.preset === 'QANDA');
  }, [discussion?.preset]);

  useEffect(() => {
    const tmpItems = [];

    if (isPlaylist) {
      tmpItems.push({
        key: 'playlist',
        label: (
          <span data-cy="tab-playlist">
            {screens.sm ? (
              t('playlist')
            ) : (
              <>
                <UnorderedListOutlined
                  style={{
                    fontSize: 16,
                    color:
                      pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
                  }}
                />
                {activeKey === 'playlist' && t('playlist')}
              </>
            )}
          </span>
        ),
        children: (
          <div className="tab__container">
            <Playlist playlist={content} isEmbed={isEmbed} />
          </div>
        )
      });
    }

    if (shouldShowPoll && !screens.sm && activeQuestion) {
      tmpItems.push({
        key: 'poll',
        label: (
          <span data-cy="tab-poll">
            <>
              <FaPoll style={{ fontSize: 16, marginRight: 16 }} />
              {activeKey === 'poll' && t('poll')}
            </>
          </span>
        ),
        children: (
          <div className="tab__container">
            <Poll
              pollId={pollId}
              color={accentColor}
              theme={pageTheme}
              isEmbed={true}
              isMuted={isMuted}
              contentType={contentType}
            />
          </div>
        )
      });
    }

    if (shouldShowDiscussion) {
      tmpItems.push({
        key: 'discussion',
        label: (
          <Badge count={count} offset={[12, 0]} data-cy="discussion-count">
            <span data-cy="tab-discussion">
              {screens.sm ? (
                t(isQAndA ? 'qanda' : 'discussion')
              ) : (
                <>
                  <CommentOutlined
                    style={{
                      fontSize: 16,
                      color:
                        pageTheme === ' dark'
                          ? 'rgba(255, 255, 255, .85'
                          : 'auto'
                    }}
                  />
                  {activeKey === 'discussion' && t('discussion')}
                </>
              )}
            </span>
          </Badge>
        ),
        children: (
          <Discussion
            discussionId={discussion?.id}
            theme={pageTheme}
            color={accentColor}
            webTvId={webTvId}
            playlistId={playlist?.id}
          />
        )
      });
    }

    if (shouldShowChapter) {
      tmpItems.push({
        key: 'chapters',
        label: (
          <span data-cy="tab-chapter">
            {screens.sm ? (
              t('chapters')
            ) : (
              <>
                <BookOutlined
                  style={{
                    fontSize: 16,
                    color:
                      pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
                  }}
                />
                {activeKey === 'chapters' && t('chapters')}
              </>
            )}
          </span>
        ),
        children: (
          <div className="tab__container">
            <Chapters
              mediaId={contentTypePlaying === 'media' ? contentIdPlaying : null}
              liveId={contentTypePlaying === 'live' ? contentIdPlaying : null}
              playlistId={playlist?.id}
              webTvId={webTvId}
              hasUniqueChapterFeed={playlist?.hasUniqueChapterFeed}
              allowClick={
                contentTypePlaying === 'media' ||
                (contentTypePlaying === 'live' && contentPlaying?.hasDVR)
              }
              color={accentColor}
            />
          </div>
        )
      });
    }

    if (shouldShowTranscript) {
      tmpItems.push({
        key: 'transcript',
        label: (
          <span data-cy="tab-transcript">
            {screens.sm ? (
              t('transcript')
            ) : (
              <>
                <TranslationOutlined
                  style={{
                    fontSize: 16,
                    color:
                      pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
                  }}
                />
                {activeKey === 'transcript' && t('transcript')}
              </>
            )}
          </span>
        ),
        children: (
          <div className="tab__container">
            <Transcript subtitles={transcripts} color={accentColor} />
          </div>
        )
      });
    }

    if (shouldShowFile) {
      tmpItems.push({
        key: 'files',
        label: (
          <span data-cy="tab-file">
            {screens.sm ? (
              t('files')
            ) : (
              <>
                <FileOutlined
                  style={{
                    fontSize: 16,
                    color:
                      pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
                  }}
                />
                {activeKey === 'files' && t('files')}
              </>
            )}
          </span>
        ),
        children: (
          <div className="tab__container">
            <Files files={files} />
          </div>
        )
      });
    }

    setItems(tmpItems);
  }, [
    screens,
    isPlaylist,
    isQAndA,
    shouldShowPoll,
    shouldShowDiscussion,
    shouldShowChapter,
    shouldShowFile,
    activeKey,
    activeQuestion,
    count
  ]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        maxWidth: '620px',
        maxHeight: '450px',
        margin: '15px 0',
        height: '600px',
        border: '5px solid #1d89b0',
        borderTop: 'none'
      }}
    >
      <Tabs defaultActiveKey="1">
        {/* {isPlaylist && (
        <TabPane
          tab={<span data-cy="tab-playlist">{t('playlist')}</span>}
          key="0"
        >
          <Playlist
            playlist={content}
            color={accentColor}
            theme={theme}
            isEmbed={isEmbed}
          />
        </TabPane>
      )} */}

        {/* {shouldShowDiscussion && discussionId && (
        <TabPane
          tab={
            <span data-cy="tab-discussion">
              <CommentOutlined />
              {t('discussion')}
            </span>
          }
          key="1"
        >
          {process.browser && (
          <Discussion discussionId={discussionId} theme={theme} />
          )}
        </TabPane>
      )} */}

        {/* {shouldShowChapter && ( */}
        <TabPane
          tab={
            <span data-cy="tab-chapter">
              {/* <BookOutlined /> */}
              {t('Déroulement')}
            </span>
          }
          key="2"
        >
          {/* {contentType === 'playlist' && !hasUniqueChapterFeed && (
            <h3 className="tab-title">
              {t('chapters')} {title}
            </h3>
          )} */}
          <Chapters
            mediaId={contentTypePlaying === 'media' ? contentIdPlaying : null}
            liveId={contentTypePlaying === 'live' ? contentIdPlaying : null}
            playlistId={playlist?.id}
            webTvId={webTvId}
            hasUniqueChapterFeed={playlist?.hasUniqueChapterFeed}
            allowClick={
              contentTypePlaying === 'media' ||
              (contentTypePlaying === 'live' && contentPlaying?.hasDVR)
            }
            color={accentColor}
          />
        </TabPane>
        {/* )} */}

        <TabPane
          tab={
            <span data-cy="tab-transcript">
              {/* <TranslationOutlined /> */}
              {t('À propos')}
            </span>
          }
          key="3"
          style={{
            marginBottom: '10px',
            textAlign: 'justify',
            fontSize: '12px'
          }}
        >
          {contentType === 'playlist' && (
            <h3 className="tab-title">
              {t('')} {title}
            </h3>
          )}
          <h2>Webconférence d’actualité | 07 décembre 2023</h2>
          <h2>
            Réhabiliter le parc Hlm: le dispositif "seconde vie" à la loupe
          </h2>
          <p>Avec :</p>
          <div
            style={{
              display: 'flex'
            }}
          >
            <img src="/Lise_Ananou.png" alt="Lise_Ananou" />
            <p>
              <span style={{ fontFamily: 'Roboto-Bold' }}>Lise Ananou, </span>
              responsable du pôle conception et adaptation de l’offre logement
              social, Groupe Caisse des Dépôts
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              border: '1px solid grey'
            }}
          >
            <img src="/Claire_Bruhat.jpg" alt="" />
            <p>
              <span style={{ fontFamily: 'Roboto-Bold' }}>Claire Bruhat, </span>
              directrice de l'habitat, Métropole Européenne de Lille (MEL)
            </p>
          </div>
          <div
            style={{
              display: 'flex'
            }}
          >
            <img src="/Emmanuel_Joinneau.png" alt="Emmanuel_Joinneau" />
            <p>
              <span style={{ fontFamily: 'Roboto-Bold' }}>
                Emmanuel Joinneau,{' '}
              </span>
              directeur opérationnel, groupe Vilogia
            </p>
          </div>
          <div
            style={{
              display: 'flex'
            }}
          >
            <img src="/Nicolas_Prudhomme.png" alt="Nicolas Prudhomme" />
            <p>
              <span style={{ fontFamily: 'Roboto-Bold' }}>
                Nicolas Prudhomme,{' '}
              </span>
              directeur de la maîtrise d’ouvrage et des politiques
              patrimoniales, L'Union sociale pour l'habitat
            </p>
          </div>
          <div
            style={{
              display: 'flex'
            }}
          >
            <img src="/Charles_TAMAZOUNT.png" alt="Charles Tamazount" />
            <p>
              <span style={{ fontFamily: 'Roboto-Bold' }}>
                Charles Tamazount,{' '}
              </span>
              adjoint au sous-directeur des politiques de l’habitat, DGALN-DHUP,
              ministère de la Transition écologique et de la Cohésion des
              territoires
            </p>
          </div>
          <p>Animation :</p>
          <div
            style={{
              display: 'flex'
            }}
          >
            <img src="/Dominique_Rousset.jpg" alt="Dominique_Rousset.jpg" />
            <p>
              <span style={{ fontWeight: 'bold' }}>Dominique Rousset</span>
              ,journaliste
            </p>
          </div>
          

          <p>
            <span style={{ fontFamily: 'Roboto-Bold' }}>
              Émetteur de plus de 20% des émissions de gaz à effet de serre en
              France, le bâtiment est un secteur clé dans la lutte contre le
              dérèglement climatique
            </span>
            . A cet égard, l'État a défini de nouvelles exigences de performance
            énergétique à travers la loi Climat et Résilience (2021) et la
            Stratégie nationale bas-carbone (SNBC) afin d’ancrer le secteur dans
            une trajectoire vertueuse en phase avec les objectifs de la
            transition écologique. D’ici 2034, les logements ayant une étiquette
            DPE E, F et G ne rempliront plus les critères de décence énergétique
            et, d’ici 2050, les logements devront atteindre la neutralité
            carbone.
            <span style={{ fontFamily: 'Roboto-Bold' }}>
              {' '}
              Acteurs essentiels du bâtiment et de l’immobilier, le Mouvement
              Hlm et ses partenaires (État, Banque des Territoires,
              collectivités territoriales, etc.) sont ainsi pleinement engagés
              dans la décarbonation du parc social afin de contribuer à cette
              transition.
            </span>
          </p>
          <p>
            C’est dans ce contexte que l’État, l’Union sociale pour l’habitat
            (USH) et la Caisse des Dépôts ont travaillé à l’élaboration d’un{' '}
            <span style={{ fontFamily: 'Roboto-Bold' }}>
              nouveau dispositif de financement des projets de réhabilitation
              lourde et durable de résidences Hlm baptisé « Seconde vie des
              logements locatifs sociaux ».
            </span>{' '}
            Ce dispositif a pour objectif de{' '}
            <span style={{ fontFamily: 'Roboto-Bold' }}>
              redonner au patrimoine existant un cycle de vie supplémentaire
              d’au moins 40 ans.
            </span>{' '}
            Il représente une alternative à la démolition- reconstruction et à
            la réhabilitation classique, en proposant d’intervenir sur les
            logements au travers d’une rénovation énergétique performante, tout
            en réalisant des travaux d’amélioration de la qualité de vie et
            d’usage pour les locataires (adaptation des typologies, respect des
            normes d’accessibilité, etc.). Cet ambitieux programme de travaux
            implique la mobilisation de tous les acteurs de l’habitat social et
            nécessite des investissements massifs, proches de ceux nécessaires à
            la construction neuve.
          </p>
          <p>
            Afin de soutenir cette démarche, l’Etat a mobilisé en 2023 une
            enveloppe de plus de 17 millions d’euros dans le cadre du Fonds
            national des aides à la pierre pour lancer une première
            expérimentation mobilisant 19 organismes Hlm dans le cadre de 23
            opérations, représentant près de 950 logements.{' '}
            <span style={{ fontFamily: 'Roboto-Bold' }}>
              A la suite des annonces faites au Congrès Hlm de Nantes et dans le
              cadre du Projet de loi de finances 2024, le dispositif “Seconde
              vie des logements locatifs sociaux” va être pérennisé avec
              l’ambition de lancer la réhabilitation lourde de plus de 10 000
              logements par an.
            </span>
          </p>
          <p>
            C’est dans ce contexte que le Réseau des acteurs de l’habitat
            propose{' '}
            <span style={{ fontFamily: 'Roboto-Bold' }}>
              une webconférence d’actualité le 7 décembre 2023 (14h30-16h) pour
              présenter le dispositif à l’ensemble des acteurs du logement dans
              les territoires{' '}
            </span>{' '}
            (organismes Hlm, collectivités, services de l’État, etc.).
          </p>
          <p>
            Cette webconférence sera notamment l’occasion d’échanger sur :
            <ul>
              <li>
                Les{' '}
                <b style={{ fontFamily: 'Roboto-Bold' }}>
                  enjeux de réhabilitation du parc Hlm
                </b>
                , en mettant en lumière les caractéristiques des logements
                concernés, les programmes de réhabilitation déjà mis en place
                par les bailleurs sociaux, ainsi que les contraintes et les
                difficultés auxquelles ces derniers font face pour atteindre les
                objectifs de la loi Climat et Résilience et, à plus long terme,
                de la Stratégie nationale bas-carbone.
              </li>
              <li>
                La
                <b style={{ fontFamily: 'Roboto-Bold' }}>
                  {' '}
                  philosophie, les principes et le fonctionnement du dispositif
                  “seconde vie des logements locatifs sociaux”
                </b>
                , en soulignant la manière dont il s’articule avec les
                réhabilitations classiques et les opérations de
                démolition-reconstruction, et en détaillant son modèle
                économique et ses modalités de mise en œuvre.
              </li>
              <li>
                Les{' '}
                <b style={{ fontFamily: 'Roboto-Bold' }}>
                  enjeux et les conditions d’accès au dispositif “seconde vie”
                </b>
                , en rappelant notamment son caractère stratégique dans le cadre
                plus large des partenariats entre les organismes Hlm et les
                collectivités territoriales, dans un contexte de raréfaction du
                foncier et d’attractivité des territoires.
              </li>
            </ul>
          </p>
        </TabPane>

        <TabPane
          tab={
            <span data-cy="tab-file">
              {/* <FileOutlined /> */}
              {t('Ressources')}
            </span>
          }
          key="4"
        >
          {contentType === 'playlist' && (
            <h3 className="tab-title">
              {t('files')} {title}
            </h3>
          )}
          <h2
            style={{
              textAlign: 'center'
            }}
          >
            Références utiles
          </h2>
          <h3>Rapports, guides, contributions, présentations </h3>
          <ul>
            <li>
              <a href="https://www.hautconseilclimat.fr/wp-content/uploads/2023/06/HCC_RA_2023-web-opti-1.pdf">
                Acter l’urgence, engager les moyens,{' '}
              </a>
              Rapport annuel 2023 du Haut conseil pour le climat, juin 2023
            </li>
            {/*  */}
            <li>
              <a href="https://www.consilium.europa.eu/fr/policies/green-deal/fit-for-55-the-eu-plan-for-a-green-transition/">
                Ajustement à l’objectif 55
              </a>
              , Conseil européen – Conseil de l’Union Européenne, novembre 2023
            </li>

            <li>
              <a href="https://www.ecologie.gouv.fr/sites/default/files/Cahier%20des%20charges%20Seconde%20Vie%20-%20VF.pdf">
                Cahier des charges expérimentation « seconde vie des logements
                locatifs sociaux » : critères d’éligibilité des opérations en
                2023 et règles de financement
              </a>
              , Ministère chargé du logement, mars 2023
            </li>

            <li>
              <a href="https://www.union-habitat.org/sites/default/files/articles/documents/2023-02/contributions_ush_-_cnr_logement_-_gt1.pdf">
                Contribution au CNR de l’Union Sociale pour l’Habitat,
              </a>
              Union Sociale pour l’Habitat, mars 2023
            </li>

            <li>
              <a href="https://www.union-habitat.org/actualites/entre-le-neuf-et-la-renovation-qu-est-ce-que-la-seconde-vie-du-patrimoine">
                Entre le neuf et la rénovation, qu’est-ce que la « Seconde vie »
                du patrimoine ?{' '}
              </a>
              , Rencontre professionnelle de l’Union sociale pour l’habitat,
              septembre 2022
            </li>

            <li>
              <a href="https://www.union-habitat.org/sites/default/files/articles/pdf/2022-06/renovation_therm_rapport.pdf">
                Etude sur la rénovation thermique des logements du parc social{' '}
              </a>
              , Agence nationale de contrôle du logement social, juin 2022{' '}
            </li>
            {/*  TEST */}
            <li>
              <a href="https://theshiftproject.org/wp-content/uploads/2021/10/TSP-PTEF-Habiter-dans-une-societe-bas-carbone-RF-7-octobre-2021.pdf">
                Habiter dans une société bas carbone,
              </a>{' '}
              The Shift Project, octobre 2021
            </li>
            <li>
              <a href="https://www.ecologie.gouv.fr/lancement-dune-experimentation-seconde-vie-des-logements-locatifs-sociaux">
                Lancement d’une expérimentation « Seconde vie des logements
                locatifs sociaux »
              </a>
              , Ministère de la Transition écologique et de la Cohésion des
              territoires, mai 2023
            </li>
            <li>
              <a href="https://www.banquedesterritoires.fr/sites/default/files/2023-10/Exe_brochure_Eclairage_29_A4_2023__WEB_HD%5B2%5D.pdf">
                La performance énergétique du logement HLM : mieux que dans le
                privé, mais des différences fortes selon les territoires
              </a>
              , Eclairages, n°29, Banque des territoires, octobre 2023
            </li>
            <li>
              <a href="https://www.banquedesterritoires.fr/sites/default/files/2023-09/BDT_PERSPECTIVES_2023_N10.pdf">
                L‘étude sur le logement social
              </a>
              , Perspectives 2023, Banque des territoires, 2023
            </li>
            <li>
              <a href="https://www.ecologie.gouv.fr/logements-locatifs-sociaux-restructuration-lourde-et-renovation-thermique">
                Logements locatifs sociaux : restructuration lourde et
                rénovation thermique
              </a>
              , Ministère de la Transition écologique et de la Cohésion des
              territoires, mai 2023
            </li>
            <li>
              <a href="https://www.carbone4.com/files/wp-content/uploads/2020/01/Publication-Carbone-4-Pouget-Neutralite-et-Logements.pdf">
                Neutralité et logements : à quelles conditions le secteur
                résidentiel peut-il atteindre la neutralité carbone ?
              </a>
              , POUGET Consultants et Carbone 4, janvier 2020
            </li>
            <li>
              <a href="https://www.ecologie.gouv.fr/sites/default/files/SNBC-2%20synthe%CC%80se%20VF.pdf">
                Stratégie nationale bas-carbone – Synthèse
              </a>
              , Ministère de la transition écologique et solidaire, mars 2020
            </li>
            {/*  fin TEST */}

            {/* FIN TEST */}
          </ul>
          <h3>Ouvrages et articles </h3>
          <ul>
            {/* TEST */}
            <li>
              Banque des territoires,
              <a href="https://www.banquedesterritoires.fr/logements-sociaux-rehabilitation-energetique-pensee-avec-les-locataires">
                {' '}
                « Logement social : rénover pour éradiquer les passoires
                thermiques »{' '}
              </a>
              , juin 2023
            </li>
            <li>
              BAYIK Nil, VAUQUELIN Gil,{' '}
              <a href="https://www.banquedesterritoires.fr/logement-social-cap-sur-les-renovations-energetiques-ambitieuses">
                {' '}
                « Logement social : cap sur les rénovations énergétiques
                ambitieuses ! »{' '}
              </a>
              , Localtis, Banque des territoires, juillet 2021
            </li>
            <li>
              BERTHION François-Xavier,{' '}
              <a href="https://www.union-habitat.org/actualites/performance-energetique-du-logement-faites-preuve-de-decence">
                « Performance énergétique du logement : faites preuve de décence
                ! »
              </a>
              , Actualités Habitat, décembre 2022
            </li>
            <li>
              BOUCULAT Bérénice,
              <a href="https://www.banquedesterritoires.fr/financement-renovation-thermique-logements-sociaux">
                {' '}
                Rénovation thermique des logements sociaux : quels financements
                proposer aux bailleurs sociaux ?{' '}
              </a>
              , Localtis, Banque des territoires, juin 2023
            </li>
            <li>
              BOUTANG Jérôme, TUDDENHAM Mark,{' '}
              <a href="https://www.cairn.info/revue-responsabilite-et-environnement-2018-1-page-34.htm?contenu=article">
                « L’ambitieux objectif français de neutralité carbone nette en
                2050 »
              </a>
              , Annales des mines – Responsabilité et environnement, 2018
            </li>
            <li>
              BRIERE Raphaël, FERAILLE FRESNET Adélaïde, BAVEREL Olivier, LE ROY
              Robert, TARDIVEL Yannick,{' '}
              <a href="https://hal.science/hal-01131505/">
                {' '}
                « Comparaison des bilans environnementaux de deux types de
                démolition »
              </a>
              , Journées de l’AFGC 2015, mars 2015
            </li>
            <li>
              DUGAST César, DAUNAY Julie,
              <a href="https://www.carbone4.com/article-batiment-snbc">
                {' '}
                « Le bâtiment, un secteur en première ligne des objectifs de
                neutralité carbone de la France en 2050 »{' '}
              </a>
              , Carbone 4, janvier 2019
            </li>
            <li>
              GIRAUDET Louis-Gaëtan, BOURGEOIS Cyril, QUIRION Philippe,{' '}
              <a href="https://www.cairn.info/revue-economie-et-prevision-2020-1-page-43.htm?contenu=article">
                « Efficacité économique et effets distributifs de long-terme des
                politiques de rénovation énergétique des logements »
              </a>
              , Economie & prévision, 2020
            </li>
            <li>
              HAIRABEDIAN Jordan,{' '}
              <a href="https://eco-act.com/fr/accord-de-paris/union-europeenne-adopte-fit-for-55/">
                « Fit for 55 » : la feuille de route de l’UE pour une réduction
                de 55 % des émissions d’ici à 2030 »{' '}
              </a>
              , Ecoact, janvier 2022
            </li>
            <li>
              JOUANNAIS Eve,{' '}
              <a href="https://www.union-habitat.org/quelles-strategies-patrimoniales-dans-les-territoires-en-mutation">
                {' '}
                « Quelles stratégies patrimoniales dans les territoires en
                mutation ? »{' '}
              </a>
              , Actualités Habitat, mai 2023
            </li>
            <li>
              La Banque Postale,{' '}
              <a href="https://www.labanquepostale.fr/bailleurs-sociaux/actualite/seconde-vie-du-patrimoine.html">
                {' '}
                « La seconde vie du patrimoine : une piste de développement dans
                le parc social »{' '}
              </a>
              , mars 2023
            </li>
            <li>
              SCHLACKE Sabine, WENTZIEN Helen, THIERJUNG Eva-Maria, KÖSTE
              Miriam,
              <a href="https://watermark.silverchair.com/oiab002.pdf?token=AQECAHi208BE49Ooan9kkhW_Ercy7Dm3ZL_9Cf3qfKAc485ysgAAA24wggNqBgkqhkiG9w0BBwagggNbMIIDVwIBADCCA1AGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMMUWJL60zocTAytvlAgEQgIIDIZrmyl_5ESZQWhjQKnvgN5qYGRGgoQGIuBGUn_D9vqc_093ApLl_OvvD7lBMfKXCZxEUMw6AfkBH3X_qocbpoSbloQ_mt2n0PSmSiTJKMDH-Ke-t6npoY3f2llXAV17hiTjcvSVUBc5R7jdz-Ig4BM2xVojCehyG2oXiLmS_OPYHwtb08PvYBHd7oHvICpP_sVVVZdACBgX11CoL6pjNGwAjNAXiX7DcDAyGzqMptyFzHGHcKOdePcxkxCX8Nz6GSdi1Qq6LqWSvUeLp59e358Q4XIfrxZyc8fd8GhS0UoKqLOFIg7hMMU5fAfY_UDBfbU4naS9upamZfGCsnb8EJyBnxLLU1gMaTUc5aILO2QZYZD8AwMJ6YvN-e45HvTfugKK9yf8UeexVokWHCGJIkNdOPE2i3Et0Zshty_-EE-ELXSBgoQRZllZXEXnobDaikw-zoLSJmw2sDb2yn6C2faNFW59rw8Vdlu0c94ngRqTPTnDf_M_oLaBKF0A1_8sZKcoadsJ1egn8uaJ59I59NU9YYSNyT-k_nURakHkLsm_M-6uy8KAgzv_7gIwho4lIi9lSrjFhaOV8Pc9XEG3PFOBu6DL8U9RJtY_MLNnX6ZalY1qXsZHpseREgeCeIfqr2PrqtrVW1g81yExUlNZVd3ajdavi_U0gdX23qwMKl8QwZylDIN1C5ACUiSk05_q7n3N0YzugOQt9tMZYyYf8ATm1XKknKIGDBvy_at6ijw7ynvIxlxdCYJHXX84WW0g6OT8wgGjdn-8KA8PAfk03aanMbltkN32xkH_9nM5sLgWQiY-ljIsNt8Q_BCM8AD9daoKow6vqtlXzAuVt8EfgDLJMVV6yzuezx_qzH8Q5BmIAWHInCPd54Us0EjwYVK_UVAoREjEQkDeXkXhEKk5Z7sDMEQx63gVEYrYscJIQWvkiCPOzn-kMSl7d2xxZGkWKPMiDoEs0Y5M1VngTwy8LrEAp8e-cUyxP3LYmrIVJxZEdMWsQhkcrd5ALGG_pDyIvJdeDQPJdLAACoXwRLasnY_QPWhwfMZClVNplh3gMsHg0oQ">
                {' '}
                « Implementing the EU Climate Law via the ‘Fit for 55’ package »{' '}
              </a>
              , Oxford open energy, 2022
            </li>
            <li>
              SCOHIER Claire,
              <a href="https://www.ieb.be/Arreter-de-casser-la-ville-Bilan-carbone-de-la-demolition-reconstruction-vs">
                {' '}
                « Arrêtez de casser la ville ! Bilan carbone de la
                démolition-reconstruction vs. rénovation »
              </a>
              , Bruxelles en mouvement, octobre 2023
            </li>
            <li>
              Union Sociale pour l’Habitat,{' '}
              <a href="https://www.union-habitat.org/actualites/rehabilitation-la-performance-avant-tout">
                « Réhabilitation : la performance avant tout »
              </a>
              , Actualités, mars 2023
            </li>
            <li>
              VALRANGES Diane,
              <a href="https://www.union-habitat.org/decarbonation-comment-engager-une-demarche-de-compensation-carbone">
                {' '}
                « Décarbonation : comment engager une démarche de compensation
                carbone »{' '}
              </a>
              , Actualités Habitat, avril 2023
            </li>
            <li>
              VALRANGES Diane,
              <a href="https://www.union-habitat.org/rehabilitation-lourde-l-experimentation-seconde-vie-des-logements-locatifs-sociaux-est-lancee">
                {' '}
                « Réhabilitation lourde : l’expérimentation « seconde vie des
                logements locatifs sociaux » est lancée »{' '}
              </a>
              , Actualités Habitat, avril 2023
            </li>
            <li>
              VILLOT Jonathan, ZOONEKINDT Kathleen,
              <a href="https://hal-emse.ccsd.cnrs.fr/emse-01017266v1/document">
                {' '}
                « Les solutions « smart » : conditions de transition vers une
                sobriété énergétique en logement social »{' '}
              </a>
              , 4e édition du colloque « Eau, déchets et développement durable
              », juin 2014
            </li>
          </ul>
          <h3>Cadre législatif et national</h3>
          <ul>
            <li>
              Légifrance,{' '}
              <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000042953125">
                Décret n° 2021-19 du 11 janvier 2021 relatif au critère de
                performance énergétique dans la définition du logement décent en
                France métropolitaine
              </a>
            </li>
            <li>
              Légifrance,{' '}
              <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000047980618#:~:text=%2D1%20...-,D%C3%A9cret%20n%C2%B0%202023%2D796%20du%2018%20ao%C3%BBt%202023%20pris,%C3%A0%20usage%20de%20r%C3%A9sidence%20principale">
                Décret n° 2023-796 du 18 août 2023 pris pour l'application de
                l'article 6 et de l'article 20-1 de la loi n° 89-462 du 6
                juillet 1989 et adaptant les dispositions des contrats types de
                location de logement à usage de résidence principale
              </a>
            </li>
            <li>
              Légifrance,{' '}
              <a href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000043956924">
                LOI n° 2021-1104 du 22 août 2021 portant lutte contre le
                dérèglement climatique et renforcement de la résilience face à
                ses effets
              </a>
            </li>
          </ul>
        </TabPane>
        <TabPane
          tab={
            <span data-cy="tab-file">
              {/* <FileOutlined /> */}
              {t('Actualités du Réseau')}
            </span>
          }
          key="5"
        >
          <div>
            <h3>Actualités du Réseau : </h3>
            <ul>
              <li>
                Vidéo de la webconférence d’actualité du 25/05/2023 :{' '}
                <a href="https://acteursdelhabitat.com/?Webconference-d-actualite-Le-Logement-d-abord-quel-bilan-et-quelles-perspectives-25-05-2023">
                  « Le Logement d’abord : quel bilan et quelles perspectives ? »
                </a>
              </li>
              <li>
                Vidéo de la journée « Quoi de neuf, acteurs ? » du 21/06/2023 :
                <a href="https://acteursdelhabitat.com/?Journee-d-actualite-21-06-23-Le-monde-de-l-habitat-en-chantier-s-decentralisation-et-nouveaux-defis-economiques-et-financiers">
                  « Le monde de l’habitat en chantier(s) : décentralisation et
                  nouveaux défis économiques et financiers »
                </a>
              </li>
              <li>
                Vidéo de la journée « Quoi de neuf, chercheurs ? » du 16/11/2023
                :
                <a href="https://acteursdelhabitat.com/?Quoi-de-neuf-chercheurs-Les-besoins-en-logements-a-l-heure-de-l-urgence-climatique-et-de-la-transition-ecologique-16-11-2023-4329">
                  « Les besoins en logements à l’heure de l’urgence climatique
                  et de la transition écologique »
                </a>
              </li>
            </ul>
          </div>
        </TabPane>
      </Tabs>

      <style jsx>{`
        .container {
          height: 100%;
        }
      `}</style>
      <style global jsx>{`
        .ant-tabs-tab > .ant-tabs-tab-btn {
          color: ${
            pageTheme === 'dark'
              ? 'rgba(255, 255, 255, .85) !important'
              : 'auto'
          } ;
        }
        .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
          display: unset !important;
        }

        .ant-tabs-nav {
          padding: ${screens.md ? 'initial' : '0 0 0 24px'};
          // padding-left: ${screens.md ? '48px' : 'initial'};
        }

        .ant-tabs-nav-wrap,
        .ant-tabs-nav-list {
          width: 100%;
        }

        .ant-tabs-nav-list .ant-tabs-tab {
          margin: 0 !important;
        }

        .ant-tabs-nav-list .ant-tabs-tab:last-of-type {
          margin-right: 0 !important;
        }

        .ant-tabs-tab {
          display: flex;
          justify-content: center;
          flex-grow: 1;
          // padding: ${screens.md ? '16px 8px;' : '16px 4px'};
          // margin: 0 12px;
          // background-color: green;
        }
        .ant-tabs-tab:first-child {
          background-color: #1d89b0;
          
        }
        .ant-tabs-tab:nth-child(3) {
          background-color:#ef8430;
        }
        .ant-tabs-tab:nth-child(2) {
          background-color: #91bc49;
        }
       
        .ant-tabs-tab:nth-child(4) {
          background-color: #dd5041;
        }
       
        .ant-tabs-tab:hover {
          color: #fff !important;
        }
        .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
          color: white !important;
      }
        .ant-tabs-tabpane li{
          padding-bottom: 10px;
        
      }
      .ant-tabs-tabpane img{
          width: 125px;
          padding-right: 10px;
          padding-bottom: 15px;
      }
     
        .ant-tabs-tab + .ant-tabs-tab {
          padding: 10px !important;
            margin: 0px !important;
        }

        .ant-tabs-content {
          height: ${screens.sm ? '100%' : tabHeight};
          overflow-x: ${screens.sm ? 'auto' : 'hidden'};
        }

        .ant-tabs,
        .ant-tabs-content-holder {
          height: 100%;
          max-height: 100%;
        }

        .ant-tabs-tabpane {
          height: 100%;
          padding: 24px;
        }

        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
          opacity: 0;
        }

        .tab-title {
          font-size 16px;
          margin: 0 0 16px;
        }

        // fIX FOR SAFARI
        .ant-tabs-content-holder {
          display: flex;
        }

        .ant-tabs-tab .ant-tabs-tab-btn .ant-badge {
          color: inherit;
        }
  
        .ant-tabs-tab .ant-tabs-tab-btn {
          font-weight: 500;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .ant-badge {
          color: ${accentColor} !important;
          
        }
        .ant-tabs-tab span {
          // display: flex;
          align-items: center;
          line-height: 1;
        }
        .tab__container {
          min-height: 100%;
          padding: 16px;
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #fff !important;
      }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
          color: #fff !important;
      }
      `}</style>
    </div>
  );
};

export default memo(TabsPlayer);
