import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setCookie } from 'nookies';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import useInterval from '../../utils/hooks/useInterval';
import { useCheckSession } from '../../utils/store/confidentiality';
import { useRouter } from 'next/router';

const MultiSession = ({ privacyId }) => {
  const [multiSession, setMultiSession] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const checkSession = useCheckSession();

  const router = useRouter();
  const { t } = useTranslation('common');

  const checkMultiSession = () => {
    checkSession(privacyId).then((res) => {
      setMultiSession(!res.data);
    });
  };

  useInterval(() => {
    checkMultiSession();
  }, 1000 * 60);

  useEffect(() => {
    setTimeout(() => {
      checkMultiSession();
    }, 5000);
  }, []);

  useEffect(() => {
    if (!multiSession) return;
    setCookie(null, 'contentToken', '', {});
    setIsModalOpen(true);
    setTimeout(() => {
      router.reload();
    }, 5000);
  }, [multiSession]);

  return (
    <Modal
      title={
        <span style={{ fontSize: 20 }}>
          <WarningOutlined style={{ marginRight: 16 }} />
          {t('multiple-session-title')}
        </span>
      }
      open={isModalOpen}
      closeIcon={false}
      footer={false}
      style={{
        fontSize: 20
      }}
      bodyStyle={{
        padding: '0 24px 8px'
      }}
    >
      <p>{t('multiple-session-description')}</p>
      <p>{t('multiple-session-footer')}</p>
    </Modal>
  );
};

export default MultiSession;
